<template>
    <b-container>
        <b-row class="mb-5" align-v="center">
            <b-col cols="12" lg="5">
                <h1 class="mb-5 text-center">Accompagnement <br />pré et post natal</h1>
                <p>
                    Un rendez-vous pendant la grossesse et/ou après la naissance de votre bébé.<br />
                    <br />
                    Nous aborderons ensemble les thèmes de votre choix, comme le sommeil,
                    l'alimentation, les soins quotidiens, comment porter son bébé et bien
                    plus encore. Je peux aussi vous aider pour la préparation de votre
                    valise de maternité, ou si vous avez besoin de conseils sur 
                    votre liste de naissance, ou tout simplement pour l'aménagement de 
                    votre maison.
                </p>
            </b-col>
            <b-col cols="12" lg="6" offset-lg="1">
                <non-clickable-img url="prenatal_appointment.png" />
            </b-col>
        </b-row>
        <b-row class="mb-5">
            <b-col cols="12" class="d-lg-none">
                <p>
                    Puis, vient l'arrivée de votre bébé, le séjour à la maternité
                    peut parfois être rapide, et vous n'avez pas toujours le temps de 
                    poser toutes vos questions. Vous rentrez à la maison et vous vous 
                    retrouvez seuls. Cette transition peut être plus ou moins 
                    difficile suivant les situations.<br />
                    <br />
                    C'est vous qui me guiderez lors 
                    de ce moment, et je répondrai à toutes vos interrogations.
                    Si je n'ai pas la réponse, je vous orienterai vers des professionnels
                    de confiance (sage-femme, ostéopathe, kiné, sophrologue...).<br />
                    <div id='price'>
                        <hr />
                        <p>
                            Tarifs<br />
                            50€* - 1h<br />
                            80€* - 2h<br />
                        </p>
                        <hr />
                        <em>
                            * des frais peuvent s'ajouter en fonction de la localisation de votre domicile.
                        </em>                                
                    </div>
                </p>
            </b-col>
            <b-col cols="12" lg="6" offset-lg="0" class="mt">
                <non-clickable-img url="motherhood_return.png" />
            </b-col>
            <b-col cols="6" class="d-none d-lg-inline">
                <p>
                    Puis, vient l'arrivée de votre bébé, le séjour à la maternité
                    peut parfois être rapide, et vous n'avez pas toujours le temps de 
                    poser toutes vos questions. Vous rentrez à la maison et vous vous 
                    retrouvez seuls. Cette transition peut être plus ou moins 
                    difficile suivant les situations.<br />
                    <br />
                    C'est vous qui me guiderez lors 
                    de ce moment, et je répondrai à toutes vos interrogations.
                    Si je n'ai pas la réponse, je vous orienterai vers des professionnels
                    de confiance (sage-femme, ostéopathe, kiné, sophrologue...).<br />
                    <div id='price'>
                        <hr />
                        <p>
                            Tarifs<br />
                            50€* - 1h<br />
                            80€* - 2h<br />
                        </p>
                        <hr />
                        <em>
                            * des frais peuvent s'ajouter en fonction de la localisation de votre domicile.
                        </em>                                
                    </div>
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <p id="conclusion" class="text-center">
                    Mon objectif est de vous accompagner et de vous apporter le plus de 
                    sérénité possible pour vivre cette belle aventure.
                </p>
            </b-col>            
        </b-row>
    </b-container>
</template>

<script>
import Price from '../components/Price';
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'PrePostNatalSupport',
    components: { Price, NonClickableImg },
};
</script>

<style scoped>
h1 {
    line-height: 200%;
}

p {
    text-align: justify;
    text-justify: inter-word;
}

#conclusion {
    font-family: 'Conquette';
    color: var(--primary)
}

#price p {
    color: #fff;
    background-color: var(--secondary);

    font-weight: bold;

    text-align: center;
}

#price em {
    font-size: 12px;
}
</style>
